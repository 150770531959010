// import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CategoryHeader from "../../Components/Category/CategoryHeader";
import CardProductsContainer from "../../Components/Products/CardProductsContainer";
import Pagination from "../../Components/Utilities/Pagination";
import ViewAllProductByBrandHook from "../../hook/product/ViewAllProductByBrandHook";

import { Helmet } from "react-helmet";
import SideFilter from "../../Components/Utilities/SideFilter";
import ViewSearchProductHooks from "../../hook/product/ViewSearchProductHooks";
import LoadingSpinner from "../../Components/Utilities/LoadingSpinner";
import ViewAllProductsBySubCategoryHook from "../../hook/product/ViewAllProductsBySubCategoryHook";
import Header from "./Header";
import SecondNavGallery from "./SecondNavGallery";
import next from "../../Images/back__.png";
import "./ArtGallery.css"; // Import CSS for styling
import ExhibitionCard from "./ExhibitionCard";

const ArtGallery = () => {
  const { tybe, id } = useParams();
  console.log(tybe);
  const [, , , brandById, settingsRes] = ViewAllProductByBrandHook(id);
  const [catById] = ViewAllProductsBySubCategoryHook(id);

  const [
    items,
    pagination,
    onPress,
    getProduct,
    result,
    offers,
    selectedCategorySearch,
    selectedBrandSearch,
    search,
    onChangeSetMinPrice,
    onChangeSetMaxPrice,
    minPrice,
    maxPrice,
    loadingSpinner,
  ] = ViewSearchProductHooks(
    "",
    tybe != "brand" ? id : undefined,
    undefined,
    tybe == "brand" ? id : undefined
  );

  // let products = tybe == "brand" ? items : null;

  // console.log(products);
  let hideCategory = false;
  let hideBrands = true;
  if (pagination) var pageCount = pagination;
  else pageCount = 0;

  // console.log('items');
  // console.log(items);
  console.log("settingsRes?.data?.titleAr");
  console.log(settingsRes?.data?.titleAr);
  //{`  ${items[0]?.brand?.nameAr || ' '} ${items[0] ? '-' : ' '} ${settingsRes?.data?.titleAr}`}
  return (
    <div style={{ minHeight: "670px" }}>
      <SecondNavGallery />

      <div className="">
        <a style={{ textDecoration: "none", color: "black" }} href="\">
          <p className="" style={{ float: "right", marginRight: "5px" }}>
            رجوع
            <img src={next} />
          </p>
        </a>
        <h1 className="art-gallery-title">
          {" "}
          {tybe == "brand" ? brandById?.nameAr : catById?.nameAr}
        </h1>
      </div>
      <div className="art-gallery-image-container">
        <img
          src={tybe == "brand" ? brandById?.logo : catById?.image}
          alt="Main Art"
          className="art-gallery-main-image"
        />
      </div>
      <div className="art-gallery-artist-info">
        <table className="info-table">
          <tbody>
            <tr>
              <th style={{ width: "20%" }}>
                {" "}
                {tybe == "brand" ? "السيره الشخصيه" : "العنوان"}
              </th>
              <td>
                {tybe == "brand"
                  ? brandById?.title_1
                  : catById?.parentId?.nameAr}
              </td>
            </tr>
            <tr>
              <th>{tybe == "brand" ? "صفحة أرتسي" : "التاريخ"}</th>
              <td>
                {tybe == "brand"
                  ? brandById?.title_2
                  : catById?.createdAt?.split("T")[0]}
              </td>
            </tr>
            {tybe == "brand" && (
              <tr>
                <th>اعمال فنية</th>
                <td>{brandById?.title_3}</td>
              </tr>
            )}
            <tr>
              <th>{tybe == "brand" ? "إتصل بنا" : null}</th>

              <td className="info-long-text">
                {/* عهد العمودي؛ فنانة ناشئة تشمل ممارستها متعددة التخصصات الطباعة
                والتصوير الفوتوغرافي، والأداء وفن الفيديو. يتركز عمل العمودي
                المتفرد القائم على البحث حول اقتراح تفسيرات بارعة للمجتمع، والتي
                غالبًا ما تقدم نفسها بصيغة التعليقات أو المحاكاة الساخرة.
                وبالرغم من كونها مرحة، إلا أن لها أسسًا جادة للغاية. تترك
                أعمالها المتنوعة أن الإثنوغرافيا ومفاهيمها تشكل مجالًا جاهزًا
                للتغيير والإصلاح من خلال النقد الإبداعي. تنظر العمودي إلى عملها
                كفنانة رقمية محلية، وتعيد تجميع الأجزاء المستمدة من المحتوى
                الرقمي الغزير، لتحوله إلى تركيبات جديدة، تدمج ما بين ثقافة البوب
                والتقاليد المختلفة. وكمفسرة للثقافة السعودية وحرکاتها المتنوعة
                ومستقبلها الممكن وواقعها، تستفيد العمودي من موقعها وتراثيتها ما
                بين إنجازاتها والمملكة العربية السعودية، لتناقش قضايا التمثيل
                والتاريخ. وفي بحثها حول الإثنوغرافيا المرتبطة بالسعودية، تطمح
                عهد في ممارستها إلى تقديم الهويات السعودية المعاصرة بطرية عميقة
                ومعقدة، وتعكس ذاتها أ... */}
                {tybe == "brand" ? brandById?.title_4 : null}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* <div className="exhibitions-grid">
        {items.map((exhibition, index) => (
          <ExhibitionCard
            key={index}
            image={exhibition.img}
            nameAr={"prod_img"}
            createdAt={exhibition.createdAt.split("T")[0]}
            _id={exhibition._id}
          />
        ))}
      </div> */}

      <Container>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          <p>إعمال الفنية</p>
        </div>
        <Row
          // className="d-flex justify-content-center"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            overflow: "scroll",
          }}
        >
          <Col sm="12" xs="12" md="12" className="d-flex ">
            {items &&
              items.map((exhibition, index) => (
                <a
                  href={`/product/${exhibition._id}`} // Pass obj as URL parameter
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <div className="exhibition-card">
                    <img src={`${exhibition.image}`} alt={exhibition.nameAr} />
                    <div className="exhibition-info">
                      <h2>{exhibition.nameAr}</h2>
                      <p>{exhibition.createdAt.split("T")[0]}</p>
                      <p>{exhibition.brand.nameAr}</p>
                    </div>
                  </div>
                </a>
              ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ArtGallery;
