import { CREATE_ORDER } from "../type";
import { useInsertData } from "../../hooks/useInsertData";

export const createOrders = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/orders`, body);

    dispatch({
      type: CREATE_ORDER,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: CREATE_ORDER,
      payload: error,
    });
  }
};
