
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdmingoogleAnalytics from "../../Components/Admin/AdmingoogleAnalytics";
import AdminOrderDetails from "../../Components/Admin/AdminOrderDetails";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import SideBar from "../../Components/Admin/SideBar";

const AdmingoogleAnalyticsPage = () => {
    return (
        <div className="d-flex justify-content-start">
            <div>
                <SideBar />
            </div>
            <div className="p-4" style={{ width: '100%' }}>
                <AdmingoogleAnalytics />
            </div>
        </div>
    );
};

export default AdmingoogleAnalyticsPage;
