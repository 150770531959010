import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import EditBrandHook from "../../hook/brand/EditBrandHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
const AdminEditBrand = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [
    image,
    nameAr,
    nameEn,
    BrandStatus,
    onChangeNameAr,
    onChangeNameEn,
    onChangeBrandStatus,
    onImageChange,
    handleSubmit,
    title_1,
    title_2,
    title_3,
    title_4,
    on_change_title_1,
    on_change_title_2,
    on_change_title_3,
    on_change_title_4,
  ] = EditBrandHook(id);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4"></div>
        <Col sm="8">
          <label htmlFor="image" className="d-block my-2">
            {" "}
            {t("image")}{" "}
          </label>
          {/* <img src={avatar} alt="" height="100px" width="120px" /> */}
          <div>
            <label htmlFor="upload-photo">
              <img
                id="image"
                src={image}
                alt="fzx"
                height="100px"
                width="120px"
                style={{ cursor: "pointer" }}
              />
            </label>
            <input
              type="file"
              name="photo"
              onChange={onImageChange}
              id="upload-photo"
            />
          </div>
          <label htmlFor="brandAr" className="d-block mt-3">
            {t("brand name in arabic")}
          </label>
          <input
            id="brandAr"
            onChange={onChangeNameAr}
            value={nameAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("brand name in arabic")}
          />
          <label htmlFor="brandEn" className="d-block mt-2">
            {t("brand name in english")}
          </label>
          <input
            id="brandEn"
            onChange={onChangeNameEn}
            value={nameEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("brand name in english")}
          />
          <label htmlFor="status" className="d-block mt-2">
            {" "}
            {t("brand state")}{" "}
          </label>

          <select
            value={BrandStatus}
            onChange={onChangeBrandStatus}
            name="status"
            id="status"
            className="select input-form-area mt-2 px-3 "
          >
            <option value="0"> {t("the status")}</option>
            <option value="true">{t("active")}</option>
            <option value="false">{t("not active")}</option>
          </select>
          {settingsRes.data?.gallaryTheme === true ? (
            <>
              <label htmlFor="title_1" className="d-block mt-2 ">
                {t("title_1")}{" "}
              </label>
              <input
                id="title_1"
                onChange={on_change_title_1}
                value={title_1}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder={t("title_1")}
              />{" "}
              <label htmlFor="title_2" className="d-block mt-2 ">
                {t("title_2")}{" "}
              </label>
              <input
                id="title_2"
                onChange={on_change_title_2}
                value={title_2}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder={t("title_2")}
              />{" "}
              <label htmlFor="title_3" className="d-block mt-2 ">
                {t("title_3")}{" "}
              </label>
              <input
                id="title_3"
                onChange={on_change_title_3}
                value={title_3}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder={t("title_3")}
              />{" "}
              <label htmlFor="title_4" className="d-block mt-2 ">
                {t("title_4")}{" "}
              </label>
              <input
                id="title_4"
                onChange={on_change_title_4}
                value={title_4}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder={t("title_4")}
              />
            </>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={handleSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            {t("save")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminEditBrand;
