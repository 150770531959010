import { CREATE_ORDER } from "../type";
const inital = {
  createOrder: [],
};

const checkoutReducer = (state = inital, action) => {
  switch (action.type) {
    case CREATE_ORDER:
      return {
        ...state,
        createOrder: action.payload,
      };
    default:
      return state;
  }
};

export default checkoutReducer;
