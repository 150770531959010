import {
  CREATE_REVIEW,
  REVIEWS_BY_PRODUCT_ID,
  DELETE_REVIEW,
  UPDATE_REVIEW,
  GET_ALL_REVIEWS_OF_PRODUCT,
} from "../type";
import { useInsertData } from "../../hooks/useInsertData";
import { useGetData, useGetDataToken } from "../../hooks/useGetData";
import useDeleteData from "../../hooks/useDeleteData";
import { useUpdateData } from "../../hooks/useUpdateData";

export const createReview = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/reviews`, body);

    dispatch({
      type: CREATE_REVIEW,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: CREATE_REVIEW,
      payload: error,
    });
  }
};

export const viewReviewsByProductId =
  (prodId, page, limit) => async (dispatch) => {
    try {
      const response = await useGetData(
        `/api/v1/reviews/webiste/${prodId}?page=${page}&limit=${limit}`
      );

      dispatch({
        type: REVIEWS_BY_PRODUCT_ID,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: REVIEWS_BY_PRODUCT_ID,
        payload: error,
      });
    }
  };

export const deleteReviewProduct = (id) => async (dispatch) => {
  try {
    const response = await useDeleteData(`/api/v1/reviews/${id}`);

    dispatch({
      type: DELETE_REVIEW,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: DELETE_REVIEW,
      payload: error,
    });
  }
};

export const updateReviewProduct = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/reviews/${id}`, body);

    dispatch({
      type: UPDATE_REVIEW,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_REVIEW,
      payload: error,
    });
  }
};

// get all review (comments and rates ) of product
export const getAllReviewsOfProduct = (productId) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/reviews/${productId}`);
    dispatch({
      type: GET_ALL_REVIEWS_OF_PRODUCT,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_REVIEWS_OF_PRODUCT,
      payload: error.response,
    });
  }
};
