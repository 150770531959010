import React from "react";
import Slider from "react-slick";
import "./ArtistsCarousel.css"; // Import CSS for styling

import hana from "../../Images/five.jpeg";
import mona from "../../Images/five.jpeg";
import rozen from "../../Images/five.jpeg";
import samiya from "../../Images/five.jpeg";
import aliyah from "../../Images/five.jpeg";
import samia from "../../Images/five.jpeg";
import HomeBrandHook from "../../hook/brand/HomeBrandHook";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ArtistsCarousel = () => {
  const [brand, loading] = HomeBrandHook();
  const { t } = useTranslation();

  const artists = [
    { name: "Hana", img: hana },
    { name: "Mona Al-Ghuribi", img: mona },
    { name: "Rozen Khaled", img: rozen },
    { name: "Samiya Fawzi", img: samiya },
    { name: "Aliyah Khaled", img: aliyah },
    { name: "Samia Jamal", img: samia },
    { name: "Samia Jamal", img: samia },
    { name: "Samia Jamal", img: samia },
  ];

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="disp-6">
      <div className="artists-carousel">
        <Slider {...settings}>
          {/* {artists.map((artist, index) => (
            <div key={index} className="artist">
              <img src={artist.img} alt={artist.name} />
              <p>{artist.name}</p>
            </div>
          ))} */}

          {loading === false ? (
            brand.data ? (
              brand.data.map((el) => {
                return (
                  <div key={el._id} className="artist">
                    <a href={`/products/artGallery/brand/${el._id}`}>
                      <img src={el.logo} alt={el.name} />
                    </a>

                    <p style={{ fontWeight: "bold" }}>{el.nameAr}</p>
                  </div>
                );
              })
            ) : (
              <h3 className="text-center"> {t("no brands")} </h3>
            )
          ) : (
            <Spinner animation="border" variant="primary" />
          )}
        </Slider>
      </div>
      <p style={{ textAlign: "center", marginBottom: "20px" }}>
        <a href="allbrand"> المزيد من الفنانين </a>
      </p>
    </div>
  );
};

export default ArtistsCarousel;
