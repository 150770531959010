import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllReviewsOfProduct,
  deleteReviewProduct,
} from "../../redux/actions/reviewActions";
import notify from "../useNotification";

const AdminProductReviewsHook = (productId) => {
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [reviewId, setReviewId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setLoadingSpinner(true);
    dispatch(getAllReviewsOfProduct(productId));
    setLoadingSpinner(false);
  }, [dispatch, productId]);

  const allReviewsOfPrduct = useSelector(
    (state) => state.reviewReducer.allReviewsOfProduct
  );

  // delete the review
  const showDeleteModal = (id) => {
    setReviewId(id);
    setDeleteModal(true);
  };
  // close modal before delete
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  // delete review
  const deleteReview = async () => {
    await dispatch(deleteReviewProduct(reviewId));
    setDeleteModal(false);
    setTimeout(async () => {
      notify(t("Delete successfully"), "success");
      await dispatch(getAllReviewsOfProduct(productId));
      setDeleteModal(false);
    }, 1000);
  };

  return [
    loadingSpinner,
    allReviewsOfPrduct,
    deleteModal,
    showDeleteModal,
    closeDeleteModal,
    deleteReview,
  ];
};

export default AdminProductReviewsHook;
