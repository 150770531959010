import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminExcel from "../../Components/Admin/AdminExcel";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import SideBar from "../../Components/Admin/SideBar";
import Pagination from "../../Components/Utilities/Pagination";

const AdminExcelPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
        <AdminExcel />
      </div>
    </div>
  );
};

export default AdminExcelPage;
