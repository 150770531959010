import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminAddSlider from "../../Components/Admin/AdminAddSlider";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import SideBar from "../../Components/Admin/SideBar";

const AdminAddSliderPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
        <AdminAddSlider />
      </div>
    </div>
  );
};

export default AdminAddSliderPage;
