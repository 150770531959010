import { Col, Container, Row } from "react-bootstrap"
import OfferDetails from "../../Components/Offers/Offer-Details/OfferDetails"
import VendorSideBar from "../../Components/Vendor/VendorSideBar"


const VendorOfferDetailsPage = () => {

    return    (
        <Container >
        <Row className='py-3'>
            <Col sm="3" xs="2" md="2">
            <VendorSideBar/>
            </Col>
            <Col sm="9" xs="10" md="10">

            <OfferDetails/>

            </Col>
        </Row>
    </Container>
    )

}

export default VendorOfferDetailsPage