import {
  USER_ADD_ADDRESS,
  GET_USER_ADDRESS_BY_ID,
  DELETE_USER_ADDRESS,
  USER_EDIT_ADDRESS,
  GET_ONE_ADDRESS,
} from "../type";
import { useGetData, useGetDataToken } from "../../hooks/useGetData";
import { useInsertData } from "../../hooks/useInsertData";
import useDeleteData from "../../hooks/useDeleteData";
import { useUpdateData } from "../../hooks/useUpdateData";

export const addUserAddress = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/users/userAddress/add`, body);

    dispatch({
      type: USER_ADD_ADDRESS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: USER_ADD_ADDRESS,
      payload: error,
    });
  }
};
export const editUserAddress = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(
      `/api/v1/users/userAddress/editAddress/${id}`,
      body
    );

    dispatch({
      type: USER_EDIT_ADDRESS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: USER_EDIT_ADDRESS,
      payload: error,
    });
  }
};

export const getUserAddress = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(
      `/api/v1/users/userAddress/getByUesr/${id}`
    );

    dispatch({
      type: GET_USER_ADDRESS_BY_ID,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_ADDRESS_BY_ID,
      payload: error,
    });
  }
};
export const getOneUserAddress = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(
      `/api/v1/users/userAddress/user/getOneAddress/${id}`
    );

    dispatch({
      type: GET_ONE_ADDRESS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_ADDRESS,
      payload: error,
    });
  }
};

export const deleteUserAddress = (id) => async (dispatch) => {
  try {
    const response = await useDeleteData(
      `/api/v1/users/userAddress/deleteAddress/${id}`
    );

    dispatch({
      type: DELETE_USER_ADDRESS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: DELETE_USER_ADDRESS,
      payload: error,
    });
  }
};
