import { CREATE_NEW_SLIDER, GET_ALL_SLIDER, UPDATE_SLIDER, GET_ONE_SLIDER } from "../type";
const inital = {
  newSlider: [],
  allSlider: [],
  updateSlider: [],
  oneSlider: [],

};

const sliderReducer = (state = inital, action) => {
  switch (action.type) {
    case CREATE_NEW_SLIDER:
      return {
        ...state,
        newSlider: action.payload,
      };
    case GET_ALL_SLIDER:
      return {
        ...state,
        allSlider: action.payload,
      };
    case UPDATE_SLIDER:
      return {
        ...state,
        updateSlider: action.payload,
      };
    case GET_ONE_SLIDER:
      return {
        ...state,
        oneSlider: action.payload,
      };
    default:
      return state;
  }
};
export default sliderReducer;
