import React from "react";
import { Row, Col } from "react-bootstrap";
import EditAddressHook from "../../hook/user/EditAddressHook";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserMapLocation from "./UserMapLocation";
import { t } from "i18next";
import { useSelector } from "react-redux";

const UserEditAddress = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [
    name,
    zone,
    city,
    address,
    mobile,
    closeSign,
    onChangeName,
    onChangeZone,
    onChangeCity,
    onChangeAddress,
    onChangeMobile,
    onChangeCloseSign,
    onSubmit,
    latitude,
    longitude,
    onChangeLatLang

  ] = EditAddressHook(id);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-2">اضافة عنوان جديد</div>
        <Col sm="8">
          <input
            value={name}
            onChange={onChangeName}
            type="text"
            className="input-form d-block mt-3 px-3"
            placeholder={t("Title nam")}
          />
          <textarea
            value={closeSign}
            onChange={onChangeCloseSign}
            className="input-form-area p-2 mt-3"
            rows="2"
            cols="50"
            placeholder={t("nearest mark")}
          />
          <input
            value={mobile}
            onChange={onChangeMobile}
            type="text"
            className="input-form d-block mt-3 px-3"
            placeholder={t("phone number")}
          />
          <input
            value={zone}
            onChange={onChangeZone}
            type="text"
            className="input-form d-block mt-3 px-3"
            placeholder={t("region")}
          />
          <input
            value={city}
            onChange={onChangeCity}
            type="text"
            className="input-form d-block mt-3 px-3"
            placeholder={t("city")}
          />
          <textarea
            value={address}
            onChange={onChangeAddress}
            className="input-form-area p-2 mt-3"
            rows="4"
            cols="50"
            placeholder={t("address desc")}
          />
        </Col>
      </Row>
      <Row>
        <UserMapLocation latitude={latitude} longitude={longitude} onPress={onChangeLatLang} />
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button onClick={onSubmit} className="btn-save d-inline mt-2 " style={{ backgroundColor: settingsRes?.data?.primaryColor }}>
            {t("Save the title modification")}
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default UserEditAddress;
