import { ADD_ORDER_HISTORY, GET_ALL_ORDER_HISTORY } from "../type";

const initial = {
    addOrderHistory:[],
    getAllOrderHistory:[]
}

const orderHistoryReducer = (state=initial,action) => {

    switch(action.type){

        case ADD_ORDER_HISTORY:
            return {
                ...state,
                addOrderHistory:action.payload
            }
        case GET_ALL_ORDER_HISTORY:
            return {
                ...state,
                getAllOrderHistory:action.payload
            }
        default: return state
    }
    

}

export default orderHistoryReducer