
import React from "react";
import AdminEditBrand from "../../Components/Admin/AdminEditBrand";


import SideBar from "../../Components/Admin/SideBar";

const AdminEditBrandPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
        <AdminEditBrand/>
      </div>
    </div>
  );
};

export default AdminEditBrandPage;
