import React from "react";
import AdminUserTransaction from "../../Components/Admin/AdminUserTransaction";
import SideBar from "../../Components/Admin/SideBar";

const AdminUserTransactionPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
<AdminUserTransaction/>
      </div>
    </div>
  );
};

export default AdminUserTransactionPage;
