import React, { useEffect, useState } from "react";
import { createBrand } from "../../redux/actions/brandAction";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import avatar from "../../Images/avatar.png";
import { getSettings } from "../../redux/actions/settingsAcions";

const AddBrandHook = () => {
  const dispatch = useDispatch();
  const [img, setImg] = useState(avatar);
  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [title_1, setTitle_1] = useState("");
  const [title_2, setTitle_2] = useState("");
  const [title_3, setTitle_3] = useState("");
  const [title_4, setTitle_4] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPress, setIsPress] = useState(false);
  const res = useSelector((state) => state.allBrand.brand);
  const onChangeNameAr = (e) => {
    setNameAr(e.target.value);
  };
  const onChangeNameEn = (e) => {
    setNameEn(e.target.value);
  };
  const on_change_title_1 = (e) => {
    setTitle_1(e.target.value);
  };
  const on_change_title_2 = (e) => {
    setTitle_2(e.target.value);
  };
  const on_change_title_3 = (e) => {
    setTitle_3(e.target.value);
  };
  const on_change_title_4 = (e) => {
    setTitle_4(e.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImg(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };
  const handleSubmit = async (event) => {
    if (nameAr === "" || nameEn === "" || selectedFile === null) {
      notify("من فضلك اكمل البيانات", "warn");

      return;
    }
    event.preventDefault();
    const formData = new FormData();
    formData.append("nameAr", nameAr);
    formData.append("nameEn", nameEn);
    formData.append("title_1", title_1);
    formData.append("title_2", title_2);
    formData.append("title_3", title_3);
    formData.append("title_4", title_4);

    formData.append("logo", selectedFile);
    setIsPress(true);
    await dispatch(createBrand(formData));
    setLoading(false);
  };

  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  useEffect(() => {
    if (loading === false) {
      setImg(avatar);
      setNameAr("");
      setNameEn("");
      setTitle_1("");
      setTitle_2("");
      setTitle_3("");
      setTitle_4("");

      setSelectedFile(null);
      setLoading(true);
      setTimeout(() => setIsPress(false), 1000);

      if (res.status === 201) {
        notify("تم الاضافة بنجاح", "success");
      } else {
        notify("فشل في عملية الاضافه", "error");
      }
    }
  }, [loading]);
  return [
    img,
    nameAr,
    nameEn,
    isPress,
    loading,
    handleSubmit,
    onImageChange,
    onChangeNameAr,
    onChangeNameEn,
    settingsRes,
    title_1,
    title_2,
    title_3,
    title_4,
    on_change_title_1,
    on_change_title_2,
    on_change_title_3,
    on_change_title_4,
  ];
};

export default AddBrandHook;
