import {
  GET_ALL_ORDERS_BY_USER,
  GET_ALL_ORDERS_BY_ADMIN,
  GET_ONE_ORDER,
  UPDATA_ORDER_STATUS,
  GET_ALL_ORDER_STATUS,
  PRINT_INVOICE_ORDER,
  GET_ORDERS_BY_DATE,
  PRINT_INVOICE_SHIPPMENTS,
  CREATE_SHIPPMENTS,
  REFUNDORDER
} from "../type";
const inital = {
  ordersByUser: [],
  ordersByAdmin: [],
  oneOrder: [],
  updateOrder: [],
  orderStatus: [],
  printInvoice: [],
  printShippment: [],
  orderBydate: [],
  createShippment: [],
  refundOrderItem:[]
};

const orderReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_ORDERS_BY_USER:
      return {
        ...state,
        ordersByUser: action.payload,
      };
    case GET_ALL_ORDERS_BY_ADMIN:
      return {
        ...state,
        ordersByAdmin: action.payload,
      };
    case GET_ONE_ORDER:
      return {
        ...state,
        oneOrder: action.payload,
      };
    case UPDATA_ORDER_STATUS:
      return {
        ...state,
        updateOrder: action.payload,
      };
    case GET_ALL_ORDER_STATUS:
      return {
        ...state,
        orderStatus: action.payload,
      };
    case GET_ORDERS_BY_DATE:
      return {
        ...state,
        orderBydate: action.payload,
      };
    case PRINT_INVOICE_ORDER:
      return {
        ...state,
        printInvoice: action.payload,
      };
    case PRINT_INVOICE_SHIPPMENTS:
      return {
        ...state,
        printShippment: action.payload,
      };
    case CREATE_SHIPPMENTS:
      return {
        ...state,
        createShippment: action.payload,
      };
      case REFUNDORDER:
        return {
          ...state,
          refundOrderItem: action.payload,
        };
    default:
      return state;
  }
};

export default orderReducer;
