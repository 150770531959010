import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom";
import {
  getOneTransaction,
  updateTransaction,
} from "../../redux/actions/walletAction";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

const AdminEditTransactionHook = (id, userId) => {
  const [price, setPrice] = useState(0);
  const [type, setType] = useState("0");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const [check, setCheck] = useState(false);
  const ref = useRef(null)

  const onChangePrice = (e) => {
    e.persist();
    setPrice(e.target.value);
  };
  const onChangeDescription = (e) => {
    e.persist();
    setDescription(e.target.value);
  };
  const onChangeType = (e) => {
    e.persist();
    setType(e.target.value);
  };

  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const get = async () => {
      await dispatch(getOneTransaction(id));
    };
    get();
  }, []);

  const oneTransactionResponse = useSelector(
    (state) => state.walletReducer.oneTransaction
  );

  useEffect(() => {
    if (oneTransactionResponse) {
      setPrice(oneTransactionResponse.price);
      setType(oneTransactionResponse.type);
      setDescription(oneTransactionResponse.describtion);
    }
  }, [oneTransactionResponse]);

  const onSubmit = async () => {
    // console.log(price);
    // console.log(type);
    if (price === "0" || type === "0") {
      return notify("من فضلك اكمل البيانات", "error");
    } else {
      setCheck(true);
      ref.current.continuousStart()
      setTimeout(async () => {
        setLoading(true);
        await dispatch(
          updateTransaction(id, {
            price: price,
            type: type,
            user: userId,
            describtion: description,
            addedBy: user._id,
          })
        );
        setLoading(false);
        setCheck(false);
      }, 1000);
    }
  };

  const updateTransactionResponse = useSelector(
    (state) => state.walletReducer.updateTransaction
  );
  useEffect(() => {
    if (loading === false) {
      setTimeout(() => setLoading(true), 1500);
      if (updateTransactionResponse) {
        notify("تم التعديل بنجاح", "success");
        ref.current.complete()
        // console.log(userId)
        setTimeout(()=>{ navigate(`/admin/userTransactions/${userId}`)},1000)
      } else {
        notify("فشل في عملية الاضافه", "error");
      }
    }
  }, [loading]);

  return [
    price,
    type,
    description,
    onChangePrice,
    onChangeDescription,
    onChangeType,
    onSubmit,
    check,
    ref
  ];
};

export default AdminEditTransactionHook;
