import React from "react";
import "./ExhibitionCard.css"; // Import CSS for styling

const ExhibitionCard = ({ image, nameAr, createdAt, _id, parentId }) => {
  const obj = {
    nameAr,
    image,
    _id,
    createdAt,
  };
  const objToString = (obj) => {
    return encodeURIComponent(JSON.stringify(obj));
  };

  return (
    <a
      href={`/products/artGallery/sub_category/${_id}`} // Pass obj as URL parameter
      style={{ textDecoration: "none", color: "#000" }}
    >
      <div className="exhibition-card">
        <img src={`${image}`} alt={nameAr} />
        <div
          className="exhibition-info"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <p>{parentId?.nameAr}</p>
          <p>{nameAr}</p>
          <p>{createdAt.split("T")[0]}</p>
        </div>
      </div>
    </a>
  );
};

export default ExhibitionCard;
