import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { getAllProductsSearch } from "../../redux/actions/productsAction";

const ViewSearchProductHooks = (searchWord, subId, categoryId, brandId) => {
  //const location = useLocation();
  //console.log(window.location.pathname);

  const [wordSearch, setWordSearch] = useState("");
  const [loadingSpinner, setLoadingSpinner] = useState(true);

  let [items, setItems] = useState([]);
  let [offers, setOffers] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [result, setResult] = useState(0);
  const [searchCategories, setSearchCategories] = useState([]);
  const [searchBrand, setSearchBrand] = useState([]);

  const [searchPaginationCategories, setSearchPaginationCategories] = useState(
    []
  );
  const [searchPaginationBrand, setPaginationSearchBrand] = useState([]);

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [flag, setFlag] = useState(0);
  //const[categoryId,setCategoryId]=useState(category_id);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const location = useLocation();
  const { id } = useParams();

  // console.log('brand id');
  // console.log(id);

  const dataType = location.state;
  // console.log('xxxxxxxxxx');
  // console.log(dataType);

  const keyword = useSelector((state) => state.allProduct.keyword);

  // searchWord=useMemo(()=>{
  //   if(searchWord !=='') return wordSearch;
  //   else return searchWord;
  // },[searchWord ,wordSearch]);

  async function onChangeSetMinPrice(e) {
    setMinPrice(e.target.value);
    setFlag(1);
  }

  async function onChangeSetMaxPrice(e) {
    setMaxPrice(e.target.value);
    setFlag(1);
  }

  //console.log('search product hook');
  let limit = 8;
  const dispatch = useDispatch();
  let page = localStorage.getItem("page");
  if (page == null || page == 0) page = 1;
  // console.log('page');
  // console.log(page);

  const getProduct = async () => {
    // console.log('searchWord in geProduct()');
    // console.log(searchWord);
    let word = "";
    if (searchWord !== undefined && searchWord.trim !== "") word = wordSearch;
    sortData();

    if (location?.pathname === "/homeOffers") {
      // console.log("first");
      setLoadingSpinner(true);

      await dispatch(
        getAllProductsSearch(`limit=8&page=${page}&showOffer=true`)
      );

      setLoadingSpinner(false);
    } else {
      // console.log('second')
      // console.log('subId')
      // console.log(subId);
      setWordSearch(searchWord);
      // console.log('searchWord');
      // console.log(searchWord);

      setLoadingSpinner(true);
      // console.log('keyword');
      // console.log(keyword);

      if (keyword?.type == "keyword") word = keyword.keyword;

      if (keyword?.type == "cat") categoryId = keyword.keyword;

      /////////////////////////////////////////////////////////
      let dispatchUrl = `sort=${sort}&limit=${limit}&categories=${categoryId}&brand=${brandId}&keyword=${word}&subCategory=${subId}&page=${page}&minPrice=${minPrice}&maxPrice=${maxPrice}`;
      ////////////////////////////////////////////////////////

      // console.log('dispatchUrl');
      // console.log(dispatchUrl);
      //if(dataType=='isFeatured') console.log('doneee');
      // if(keyword){
      if (dataType == "isFeatured") dispatchUrl += `&isFeatured=true`;

      //if(minPrice)

      if (keyword || id || dataType)
        await dispatch(getAllProductsSearch(dispatchUrl, keyword));

      setLoadingSpinner(false);
    }

    //return searchWord;
  };

  useEffect(() => {
    getProduct();
    //console.log('set search use effect')
    //setWordSearch(searchWord);
    window.scrollTo(0, 0);
  }, [dispatch]);

  const allproduct = useSelector(
    (state) => state.allProduct.allProductsInSearchPage
  );

  console.log("allproduct");
  console.log(allproduct);

  // const offers=0;
  //const offers = useSelector((state) => state.allProduct.allProducts);

  // this use effect called in products by category page
  useEffect(() => {
    if (categoryId) {
      setSearchCategories([categoryId]);
    }
  }, [categoryId]);

  // this use effect called in products by brand
  useEffect(() => {
    if (brandId) {
      setSearchBrand([brandId]);
    }
  }, [brandId]);

  const onPress = async (page) => {
    let word = "";
    //console.log(searchWord);
    if (searchWord != undefined) word = wordSearch;
    sortData();

    let search_brand_param = searchPaginationBrand;
    let search_categories_param = searchPaginationCategories;

    if (!searchPaginationBrand || searchPaginationBrand.length === 0) {
      //console.log('no brands');
      // console.log('my search 1');
      setSearchBrand([0]);
      search_brand_param = 0;
    }

    if (
      !searchPaginationCategories ||
      searchPaginationCategories.length === 0
    ) {
      // console.log('my search 2');
      setSearchCategories([0]);
      search_categories_param = 0;
    }

    let pagination_url = "";
    if (location?.pathname === "/homeOffers") {
      // console.log('offers url');

      if (
        searchPaginationCategories ||
        searchPaginationBrand ||
        minPrice ||
        maxPrice
      ) {
        pagination_url = `limit=${limit}&page=${page}&brand=${search_brand_param}&categories=${search_categories_param}&minPrice=${minPrice}&maxPrice=${maxPrice}&showOffer=true&subCategory=${subId}`;

        // await dispatch(
        //   getAllProductsSearch(
        //     `limit=${limit}&page=${page}&brand=${search_brand_param}&categories=${search_categories_param}&minPrice=${minPrice}&maxPrice=${maxPrice}&showOffer=true`
        //   )
        // );
      } else {
        //no categories or brand
        pagination_url = `sort=${sort}&limit=${limit}&page=${page}&keyword=${word}&showOffer=true&subCategory=${subId}`;
        // await dispatch(
        //   getAllProductsSearch(
        //     `sort=${sort}&limit=${limit}&page=${page}&keyword=${word}&showOffer=true`
        //   )
        // );
      }
    } else {
      if (
        searchPaginationCategories ||
        searchPaginationBrand ||
        minPrice ||
        maxPrice
      ) {
        pagination_url = `limit=${limit}&page=${page}&brand=${search_brand_param}&categories=${search_categories_param}&minPrice=${minPrice}&maxPrice=${maxPrice}&subCategory=${subId}`;
        // await dispatch(
        //   getAllProductsSearch(
        //     `limit=${limit}&page=${page}&brand=${search_brand_param}&categories=${search_categories_param}&minPrice=${minPrice}&maxPrice=${maxPrice}`
        //   )
        // );
      } else {
        //no categories or brand
        // console.log('my search 3');
        pagination_url = `sort=${sort}&limit=${limit}&page=${page}&keyword=${word}&subCategory=${subId}`;

        //   await dispatch(
        //     getAllProductsSearch(
        //       `sort=${sort}&limit=${limit}&page=${page}&keyword=${word}`
        //     )
        //   );
        // }
      }
    }

    // console.log('pagination_url');
    // console.log(pagination_url);
    let type;
    // const currentUrl = window.location.href;
    // console.log(currentUrl);

    if (keyword?.type === "keyword") {
      type = "keyword";
      pagination_url = `limit=${limit}&page=${page}&brand=${search_brand_param}&minPrice=${minPrice}&maxPrice=${maxPrice}&subCategory=${subId}&keyword=${keyword.keyword}`;
    } else if (keyword?.type === "cat") {
      type = "cat";
      pagination_url = `limit=${limit}&page=${page}&brand=${search_brand_param}&categories=${keyword.keyword}&minPrice=${minPrice}&maxPrice=${maxPrice}&subCategory=${subId}`;
    } else if (id && window.location.pathname.includes("/products/brand")) {
      pagination_url = `limit=${limit}&page=${page}&brand=${id}&categories=${categoryId}&minPrice=${minPrice}&maxPrice=${maxPrice}&subCategory=${subId}`;
    } else if (window.location.pathname.includes("/homeOffers")) {
      pagination_url += "&showOffers=true";
    } else if (dataType == "isFeatured") {
      pagination_url += "&isFeatured=true";
    } else {
      // console.log('doooooooo');
      pagination_url = `limit=${limit}&page=${page}&brand=${search_brand_param}&categories=${categoryId}&minPrice=${minPrice}&maxPrice=${maxPrice}&subCategory=${subId}`;
    }

    // console.log(pagination_url);

    await dispatch(getAllProductsSearch(pagination_url, keyword));
  };

  //let allItems = [];
  //const[initialItems,items]=useState([]);
  //let pagination = [];
  // let result = 0;

  useEffect(() => {
    if (location?.pathname !== "/homeOffers") {
      if (allproduct) {
        if (allproduct.data) {
          setItems(allproduct.data);
        }
      }
    } else {
      //setOffers
      if (allproduct) {
        if (allproduct.data) {
          setOffers(allproduct.data);
        }
      }
    }
  }, [allproduct, location?.pathname]);

  useEffect(() => {
    try {
      if (allproduct)
        if (allproduct.numberOfPages) setPagination(allproduct.numberOfPages);
      //else setPagination([]);
    } catch (error) {}
    try {
      if (allproduct) if (allproduct.total) setResult(allproduct.total);
      //else result = 0;
    } catch (error) {}
  }, [allproduct]);

  let sortType = "",
    sort = "";
  const sortData = () => {
    if (localStorage.getItem("sortType") !== null) {
      sortType = localStorage.getItem("sortType");
    } else {
      sortType = "";
    }
    if (sortType === "السعر من الاقل للاعلي") sort = "+price";
    else if (sortType === "السعر من الاعلي للاقل") sort = "-price";
    else if (sortType === "") sort = "";
  };

  let old_category_value = "";
  async function selectedCategorySearch(e) {
    //add item on check
    if (e.target.checked === true) {
      if (e.target.value === "0") {
        setSearchCategories([]);
      } else {
        if (old_category_value == e.target.value) {
        } else {
          setSearchCategories([...searchCategories, e.target.value]);
        }
      }
    }
    //remove item on uncheck
    else {
      let index = searchCategories.indexOf(e.target.value);
      if (index > -1) {
        // only splice array when item is found
        searchCategories.splice(index, 1); // 2nd parameter means remove one item only
      }

      old_category_value = e.target.value;
    }
    setFlag(1);
  }

  //let brand_counter=0;
  let old_brand_value = "";
  async function selectedBrandSearch(e) {
    // add items on check
    if (e.target.checked === true) {
      if (e.target.value === "0") {
        setSearchBrand([]);
      } else {
        if (old_brand_value == e.target.value) {
        } else {
          setSearchBrand([...searchBrand, e.target.value]);
        }
      }
    }
    //remove items on uncheck
    else {
      let index1 = searchBrand.indexOf(e.target.value);
      if (index1 > -1) {
        // only splice array when item is found
        searchBrand.splice(index1, 1); // 2nd parameter means remove one item only
      }
    }

    old_brand_value = e.target.value;
    setFlag(1);
  }

  async function search(searchFlag) {
    let search_brand_param = searchBrand;
    let search_categories_param = searchCategories;

    // if(old_category_value )

    if (searchFlag === 0) {
      if (!searchBrand || searchBrand.length === 0) {
        //console.log('no brands');
        setSearchBrand([]);
        search_brand_param = 0;
      }

      if (!searchCategories || searchCategories.length === 0) {
        setSearchCategories([]);
        search_categories_param = 0;
      }

      if (searchBrand.indexOf("0") !== -1) {
        search_brand_param = 0;
        setSearchBrand([]);
      }

      if (searchCategories.indexOf("0") !== -1) {
        search_categories_param = 0;
        setSearchCategories([]);
      }

      if (!minPrice) {
        setMinPrice(0);
      }

      if (!maxPrice) {
        setMaxPrice(0);
      }

      let url = "";
      if (location?.pathname === "/homeOffers") {
        url = `limit=${8}&page=${1}&brand=${search_brand_param}&categories=${search_categories_param}&minPrice=${minPrice}&maxPrice=${maxPrice}&showOffer=true`;
      } else {
        url = `limit=${8}&page=${1}&brand=${search_brand_param}&categories=${search_categories_param}&minPrice=${minPrice}&maxPrice=${maxPrice}&subCategory=${subId}`;
      }

      if (flag) {
        await dispatch(getAllProductsSearch(url, keyword));
      }

      setSearchPaginationCategories(searchCategories);
      setPaginationSearchBrand(searchBrand);
    } else {
      //remove check box
      let brandCheckBox = document.getElementsByClassName("brand");
      for (var i = 0; i < brandCheckBox.length; i++) {
        brandCheckBox[i].checked = false;
      }

      let categoryCheckBox = document.getElementsByClassName("category");
      for (var i = 0; i < categoryCheckBox.length; i++) {
        categoryCheckBox[i].checked = false;
      }

      let url = "";
      if (location?.pathname === "/homeOffers") {
        url = `limit=${8}&page=${1}&brand=${0}&categories=${0}&minPrice=${minPrice}&maxPrice=${maxPrice}&showOffer=true`;
      } else {
        url = `limit=${8}&page=${1}&brand=${0}&categories=${0}&minPrice=${minPrice}&maxPrice=${maxPrice}&subCategory=${subId}`;
      }

      await dispatch(getAllProductsSearch(url, keyword));
    }
  }

  return [
    items,
    pagination,
    onPress,
    getProduct,
    result,
    offers,
    selectedCategorySearch,
    selectedBrandSearch,
    search,
    onChangeSetMinPrice,
    onChangeSetMaxPrice,
    minPrice,
    maxPrice,
    loadingSpinner,
    settingsRes,
  ];
};

export default ViewSearchProductHooks;
