import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import {
    addProductToCart,
    getAllCartItems,
} from "../../redux/actions/cartAction";

const AdminCartHook = (id) => {
    // var user = "";
    // if (localStorage.getItem("user") != null) {
    //     user = JSON.parse(localStorage.getItem("user"));
    // }
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [itemNumber, setItemsNumber] = useState(0);
    const [cartItems, setCartItems] = useState([]);
    const [couponNameAfterApplay, setCouponNameAfterApplay] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceAfterDiscount, setTotalPriceAfterDiscount] = useState(0);
    useEffect(() => {
        const get = async () => {
            setLoading(true);
            await dispatch(getAllCartItems(id));
            setLoading(false);
        };
        get();
    }, []);
    const res = useSelector((state) => state.cartReducer.userCartItems);

    useEffect(() => {
        if (loading === false) {
            if (res && res.success === true) {
                setItemsNumber(res.numberOfCart);
                setCartItems(res.data);
                setTotalPrice(res.totalPrice);
                if (res.data.length != 0 && res.data[0].coupon) {
                    setCouponNameAfterApplay(res.data[0].coupon);
                } else {
                    setCouponNameAfterApplay("");
                }
                if (res.data.length != 0 && res.data[0].priceAfter) {
                    setTotalPriceAfterDiscount(res.data[0].priceAfter);
                } else {
                    setTotalPriceAfterDiscount(0);
                }
            } else {
                setItemsNumber(0);
                setCartItems([]);
                setCouponNameAfterApplay("");
                setTotalPriceAfterDiscount(0);
            }
        }
    }, [loading]);

    return [
        itemNumber,
        cartItems,
        totalPrice,
        couponNameAfterApplay,
        totalPriceAfterDiscount,
    ];
};

export default AdminCartHook;
