import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminEditProduct from "../../Components/Admin/AdminEditProduct";
import AdminEditUser from "../../Components/Admin/AdminEditUser";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import SideBar from "../../Components/Admin/SideBar";

const AdminEditUserPage = () => {
  return (
    // <Container className="py-3">
    //   <Row className="py-3">
    //     <Col sm="3" xs="2" md="2">
    //     <SideBar />
    //     </Col>
    //     <Col sm="9" xs="10" md="10">
    //       <AdminEditUser />
    //     </Col>
    //   </Row>
    // </Container>
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
        <AdminEditUser />
      </div>
    </div>

  );
};

export default AdminEditUserPage;
