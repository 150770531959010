import { ADD_TO_FAV, REMOVE_FROM_FAV, USER_FAVORITE } from "../type";
const inital = {
  addFavorite: [],
  removeFavorite: [],
  userFavorite: [],
};

const favoriteReducers = (state = inital, action) => {
  switch (action.type) {
    case ADD_TO_FAV:
      return {
        ...state,
        addFavorite: action.payload,
      };
    case REMOVE_FROM_FAV:
      return {
        ...state,
        removeFavorite: action.payload,
      };
    case USER_FAVORITE:
      return {
        ...state,
        userFavorite: action.payload,
      };

    default:
      return state;
  }
};

export default favoriteReducers;
