import React from "react";
import AdminSubCategories from "../../Components/Admin/AdminSubCategories";

import SideBar from "../../Components/Admin/SideBar";

const AdminSubCategoriesPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
        <AdminSubCategories />
      </div>
    </div>
  );
};

export default AdminSubCategoriesPage;
