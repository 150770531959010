import { GET_ALL_PAYMENT_METHOD } from "../type";
import { ADD_PAYMENT_METHOD } from "../type";
import { GET_ONE_PAYMENT_METHOD } from "../type";
import { EDIT_PAYMENT_METHOD } from "../type";
import { PAYMOB } from "../type";

const inital = {
  onePaymentMethod:[],
  editPaymentMethod:[],
  addPaymentMethod:[],
  paymentMethods: [],
  payMobMethod:[]
};

const paymentMethodReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case ADD_PAYMENT_METHOD:
      return {
        ...state,
        addPaymentMethod:action.payload
      }
      case GET_ONE_PAYMENT_METHOD:
        return {
          ...state,
          onePaymentMethod:action.payload
        }
      case EDIT_PAYMENT_METHOD:
        return {
          ...state,
          editPaymentMethod:action.payload
        }
      case PAYMOB :
       return {
          ...state,
          payMobMethod:action.payload
        }


    default:
      return state;
  }
};

export default paymentMethodReducer;
