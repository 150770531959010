import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import CategoryContainer from "../../Components/Category/CategoryContainer";
import LoadingSpinner from "../../Components/Utilities/LoadingSpinner";
import Pagination from "../../Components/Utilities/Pagination";
import CatPageHook from "../../hook/category/CatPageHook";
const CategoryPage = () => {
  const [category, loading , loadingSpinner , colors] = CatPageHook();
 
  return (
   
    <Col md="12" sm="4">
    {loading === true ? (
      <LoadingSpinner />
    ) : (
      <CategoryContainer category={category} loading={loading}  />
    )}
  </Col>
   
    
  );
};

export default CategoryPage;
