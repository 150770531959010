import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import ForgetPasswordHook from "../../hook/auth/ForgetPasswordHook";
import ReactInputVerificationCode from "react-input-verification-code";

const ForgetPassowrdPage = () => {
  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const [
    value,
    onChangeValue,
    sendCode,
    show,
    handleCloseModel,
    codeVerify,
    setCodeVerify,
    clearValue,
    handlePressed
  ] = ForgetPasswordHook();

  return (
    <Container style={{ minHeight: "670px" }}>
      <Modal show={show} onHide={handleCloseModel} >
        <Modal.Header>
          <Modal.Title>
            <div className="font"  >
              {" "}
              تم إرسال رمز إعادة تعيين كلمة المرور إلي البريد الإلكتروني الي أدخلته{" "}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="py-5 d-flex justify-content-center ">
            <label
              className="mx-auto title-login my-4"
              style={{ textAlign: "center" }}
            >
              ادخل الرمز 
            </label>

            <ReactInputVerificationCode placeholder="" value={codeVerify}  onChange={setCodeVerify} />
            <div className="py-5 d-flex justify-content-center ">
              <button
                onClick={handlePressed}
                className="btn-save2 d-inline mt-2 mx-2"
                style={{ backgroundColor: settingsRes?.data?.primaryColor }}
                disabled={codeVerify.length === 4 ? false : true}
              >
                تحقق
              </button>
              <button
                onClick={clearValue}
                className="btn-save2 d-inline mt-2 "
                style={{ backgroundColor: settingsRes?.data?.primaryColor }}
              >
                حذف
              </button>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Row className="py-5 d-flex justify-content-center ">
        <Col sm="12" className="d-flex flex-column ">
          <label className="mx-auto title-login">نسيت كلمة السر</label>
          <input
            placeholder="الايميل او رقم الجول..."
            type="text"
            name="email"
            value={value}
            onChange={onChangeValue}
            className="user-input my-3 text-center mx-auto"
          />
          <button
            onClick={sendCode}
            className="btn-login mx-auto mt-4"
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            ارسال كود
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default ForgetPassowrdPage;
