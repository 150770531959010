import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBrandById, updateBrands } from "../../redux/actions/brandAction";

import notify from "../useNotification";

const EditBrandHook = (id) => {
  const [image, setImage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [title_1, setTitle_1] = useState("");
  const [title_2, setTitle_2] = useState("");
  const [title_3, setTitle_3] = useState("");
  const [title_4, setTitle_4] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [BrandStatus, setBrandStatus] = useState();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const run = async () => {
      setLoadingData(true);
      await dispatch(getBrandById(id));
      setLoadingData(false);
    };
    run();
  }, []);

  const brand = useSelector((state) => state.allBrand.brandById);

  useEffect(() => {
    if (brand) {
      // console.log('------------------------')
      // console.log(brand)
      // console.log('------------------------')
      setNameAr(brand.nameAr);
      setNameEn(brand.nameEn);
      setTitle_1(brand.title_1);
      setTitle_2(brand.title_2);
      setTitle_3(brand.title_3);
      setTitle_4(brand.title_4);

      setImage(brand.logo);
      setBrandStatus(brand.status);
    }
  }, [brand]);

  const onChangeNameAr = (e) => {
    e.persist();
    setNameAr(e.target.value);
  };
  const onChangeNameEn = (e) => {
    e.persist();
    setNameEn(e.target.value);
  };
  const onChangeBrandStatus = (e) => {
    e.persist();
    setBrandStatus(e.target.value);
  };
  const on_change_title_1 = (e) => {
    setTitle_1(e.target.value);
  };
  const on_change_title_2 = (e) => {
    setTitle_2(e.target.value);
  };
  const on_change_title_3 = (e) => {
    setTitle_3(e.target.value);
  };
  const on_change_title_4 = (e) => {
    setTitle_4(e.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImage(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };

  //onsubmit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (nameAr === "" || nameEn === "") {
      notify("من فضلك اكمل البيانات", "warn");
      return;
    }

    const formData = new FormData();
    formData.append("nameAr", nameAr);
    formData.append("nameEn", nameEn);
    formData.append("title_1", title_1);
    formData.append("title_2", title_2);
    formData.append("title_3", title_3);
    formData.append("title_4", title_4);

    formData.append("logo", selectedFile);
    formData.append("status", BrandStatus);

    setTimeout(async () => {
      setLoading(true);
      // console.log('-----------------')
      // console.log(formData)
      // console.log('-----------------')
      await dispatch(updateBrands(id, formData));
      setLoading(false);
    }, 1000);
  };

  const brandRes = useSelector((state) => state.allBrand.updateBrand);

  useEffect(() => {
    if (loading === false) {
      // setTimeout(() => setLoading(true), 1000);
      if (brandRes) {
        if (brandRes.status === 200) {
          notify("تم التعديل بنجاح", "success");
          setTimeout(() => navigate("/admin/brands"), 1000);
        } else {
          notify("فشل في عملية الاضافه", "error");
        }
      }
    }
  }, [loading]);

  return [
    image,
    nameAr,
    nameEn,
    BrandStatus,
    onChangeNameAr,
    onChangeNameEn,
    onChangeBrandStatus,
    onImageChange,
    handleSubmit,
    title_1,
    title_2,
    title_3,
    title_4,
    on_change_title_1,
    on_change_title_2,
    on_change_title_3,
    on_change_title_4,
  ];
};

export default EditBrandHook;
