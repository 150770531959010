import {
  GET_ALL_SHIPPING_METHODS,
  ADD_SHIPPING_METHODS,
  GET_ONE_SHIPPING_METHODS,
  EDIT_SHIPPING_METHODS,
  GET_ONE_SHIPPING_METHODS_ID
} from "../type";
const inital = {
  shipping: [],
  oneshipping: [],
  editshipping: [],
  addshipping: [],
  shippingByID: [],

};

const shippingMethodReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_SHIPPING_METHODS:
      return {
        ...state,
        shipping: action.payload,
      };
    case ADD_SHIPPING_METHODS:
      return {
        ...state,
        addshipping: action.payload
      }
    case GET_ONE_SHIPPING_METHODS:
      return {
        ...state,
        oneshipping: action.payload
      }
    case GET_ONE_SHIPPING_METHODS_ID:
      return {
        ...state,
        shippingByID: action.payload
      }
    case EDIT_SHIPPING_METHODS:
      return {
        ...state,
        editshipping: action.payload
      }

    default:
      return state;
  }
};

export default shippingMethodReducer;
