import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersVendorById } from "../../redux/actions/vendorActions";

const ViewAllOrdersVendorHook = () => {
  const dispatch = useDispatch();
  const AllOrdersVendorById = useSelector((state)=>state.vendorReducer.getAllOrdersByVendorId) 

  const vendor =JSON.parse(localStorage.getItem("user"))

  useEffect(() => {
    dispatch(getAllOrdersVendorById(vendor._id))
  },[]);

  return [AllOrdersVendorById]
};

export default ViewAllOrdersVendorHook;
