import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import {userTransaction} from "../../redux/actions/walletAction"
const AdminUserTransactionHook = (id) =>{



    const dispatch = useDispatch()

    var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }
    useEffect(()=>{
        const get = async()=>{
           await dispatch(userTransaction(id))
        }
        get()
    },[])

    const userTransactions = useSelector(
        (state) => state.walletReducer.allUserTransaction
      );

      return [
        userTransactions
      ]


}

export default AdminUserTransactionHook