import { ADD_TICKET , UPDATE_TICKET, GET_ALL_TICKETS } from "../type"


const initial = {
tickets:[],
addTicket:[],
updateTicket:[]
}

const contactUsReducer = (state=initial,action) => {
    
    switch (action.type) {
        case ADD_TICKET:
            return {
                ...state,
                addTicket:action.payload
            };
        case GET_ALL_TICKETS:
            return {
                ...state,
                tickets:action.payload
            }
        case UPDATE_TICKET:
            return {
                ...state,
                updateTicket:action.payload
            }
         default:  return state
        
    }

}

export default contactUsReducer