import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserAddress } from "../../redux/actions/userAddressActions";

const ViewAddressHook = () => {
  const dispatch = useDispatch();
  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const get = async () => {
      if (user != "") {
        setLoading(true);
        await dispatch(getUserAddress(user._id));
        setLoading(false);
      }
    };
    get();
  }, []);

  const res = useSelector(
    (state) => state.userAddressReducer.getUserAddAddress
  );

  return [res];
};
export default ViewAddressHook;
