

import React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import VendorSideBar from "../../Components/Vendor/VendorSideBar";
import UserProfile from '../../Components/User/UserProfile';
import SideBar from "../../Components/Admin/SideBar";



const AdminProfilePage = () => {
    return    (
       <div>
 
        <Row className='py-4'>
            <Col sm="3" xs="2" md="2">
            <div>
            <SideBar />
          </div>
            </Col>
          
            <Col sm="9" xs="10" md="8">
             <UserProfile/>
        
            </Col>
        </Row>




        </div>
    
    )

}

export default AdminProfilePage

