import React from "react";
import { Col, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AdminAddTransactionHook from "../../hook/admin/AdminAddTransactionHook";
import { useSelector } from "react-redux";
const AdminAddTransaction = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [
    price,
    type,
    description,
    onChangePrice,
    onChangeDescription,
    onChangeType,
    onSubmit,
  ] = AdminAddTransactionHook(id);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  return (
    <div>
      <Helmet>
        <title>
          {`  ${t("Add new transaction")}`}
        </title>
      </Helmet>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">
          {t("Add new transaction")}
        </div>
        <Col sm="8">
          <label htmlFor="price" className="d-block mt-2 ">
            {" "}
            {t("price")}{" "}
          </label>
          <input
            id="price"
            value={price}
            onChange={onChangePrice}
            type="number"
            className="input-form d-block mt-2 px-3"
            placeholder={t("price")}
          />
          <label htmlFor="type" className="d-block mt-2 ">
            {" "}
            {t("type")}{" "}
          </label>
          <select
            value={type}
            onChange={onChangeType}
            name="type"
            id="type"
            className="select input-form-area mt-2 px-3 "
          >
            <option value="0">{t("type")}</option>
            <option value="1">{t("deposit")}</option>
            <option value="2">{t("withdraw")}</option>
          </select>
          <label htmlFor="Description" className="d-block mt-2 ">
            {t("Description")}
          </label>
          <textarea
            id="Description"
            onChange={onChangeDescription}
            value={description}
            className="input-form-area p-2 mt-2"
            rows="4"
            cols="50"
            placeholder={t("Product Description in arabic")}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            {t("add")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminAddTransaction;
