import React, { useState } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import mobile from "../../Images/mobile.png";
import deleteicon from "../../Images/delete.png";
import DeleteCartHook from "../../hook/cart/DeleteCartHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";


const CartAdminItem = ({ item }) => {
    const settingsRes = useSelector((state) => state.settingsReducers.settings);

    const currencytype = Cookies.get("i18next") === "ar" ? settingsRes.data?.defaultCurrancy.ar : settingsRes.data?.defaultCurrancy.en
    const { t } = useTranslation()

    return (
        <Col xs="12" className="cart-item-body my-2 d-flex px-2">
            <img
                width="160px"
                height="197px"
                src={(item && item?.product?.image) || mobile}
                alt=""
                style={{ objectFit: "contain" }}
            />
            <div className="w-100 mx-2">
                <Row className="justify-content-between">
                    <Col sm="12" className=" d-flex flex-row justify-content-between">
                        <div className="d-inline pt-2 cat-text">
                            {Cookies.get("i18next") == 'ar' ? (item && item?.product?.category?.nameAr) || "" : (item && item?.product?.category?.nameEn) || ""}
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-2">
                    <Col sm="12" className=" d-flex flex-row justify-content-start">
                        <div className="d-inline pt-2 cat-title">

                            {Cookies.get("i18next") == 'ar' ? (item && item?.product?.nameAr) || "" : (item && item?.product?.nameEn) || ""}
                        </div>
                        <div className="d-inline pt-2 cat-rate me-2">
                            {(item && (item?.product?.ratingsAverage).toFixed(2)) || 0}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="mt-1">
                        <div className="cat-text d-inline">{t("Brand")}</div>
                        <div className="barnd-text d-inline mx-1">
                            {Cookies.get("i18next") === 'ar' ? (item && item?.product?.brand?.nameAr) : (item && item?.product?.brand?.nameEn) || ""}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="mt-1 d-flex">
                        {item && item.color === "" ? null : (
                            <div
                                className="color ms-2 border"
                                style={{ backgroundColor: `${item && item?.color}` }}
                            ></div>
                        )}
                    </Col>
                </Row>

                <Row className="justify-content-between">
                    <Col sm="12" className=" d-flex flex-row justify-content-between">
                        <div className="d-inline pt-2 barnd-text">
                            {(item.quantity) || ""}{t("numberr")}
                        </div>
                        <div className="d-inline pt-2 barnd-text">
                            {(item && item?.product?.price1 * item?.quantity) || ""}
                        </div>
                    </Col>
                </Row>
            </div>
        </Col>
    );
};

export default CartAdminItem;
