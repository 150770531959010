import React from "react";
import AdminAddCategory from "../../Components/Admin/AdminAddCategory";
import { Col, Container, Row } from "react-bootstrap";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import AdminTerms from "../../Components/Admin/AdminTerms";
import SideBar from "../../Components/Admin/SideBar";

const AdminTermsPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
        <AdminTerms />
      </div>
    </div>
  );
};
export default AdminTermsPage;
