import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { favoriteByUserId } from "../../redux/actions/favoriteActions";

const CardContainerHook = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [favProduct, setFavProduct] = useState([]);

  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }
  const res = useSelector((state) => state.favoriteReducers.userFavorite);

  useEffect(() => {
    const get = async () => {
      if (user != "") {
        setLoading(true);
        await dispatch(favoriteByUserId(user._id));
        setLoading(false);
      }
    };
    get();
  }, []);
  useEffect(() => {
    if (loading === false) {
      if (res) {
        setFavProduct(
          res?.map((el) => {
            return el?.product?._id;
          })
        );
        // console.log(favProduct);
      }
    }
  }, [loading]);

  return [favProduct];
};

export default CardContainerHook;
