import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom";
import { addTransaction } from "../../redux/actions/walletAction"
import { useTranslation } from "react-i18next";

const AdminAddTransactionHook = (id) => {
  const [price, setPrice] = useState(0);
  const [type, setType] = useState("0");
  const [description, setDescription] = useState("");

  const [loading, setLoading] = useState(true);

  const { t } = useTranslation()

  const user = JSON.parse(localStorage.getItem("user"));



  const dispatch = useDispatch();
  const navigate = useNavigate()



  const onChangePrice = (e) => {
    e.persist();
    setPrice(e.target.value);
  };
  const onChangeDescription = (e) => {
    e.persist();
    setDescription(e.target.value);
  };
  const onChangeType = (e) => {
    e.persist();
    setType(e.target.value);
  };


  const onSubmit = async () => {
    // console.log(price)
    // console.log(type)
    if (
      price === "0" ||
      type === "0"
    ) {
      return notify("من فضلك اكمل البيانات", "error");
    }
    setLoading(true);
    await dispatch(
      addTransaction({
        price: type === "1" ? price : -price,
        type: type,
        user: id,
        describtion: description,
        addedBy: user._id
      })
    );
    setLoading(false);
  };




  const res = useSelector((state) => state.walletReducer.addTransaction);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify(t("Added successfully"), "success");
        setTimeout(() => {
          navigate(`/admin/userTransactions/${id}`)
        }, 1500);
      } else {
        notify("فشل في عملية الاضافه", "error");
      }
    }
  }, [loading]);





  return [
    price,
    type,
    description,
    onChangePrice,
    onChangeDescription,
    onChangeType,
    onSubmit,
  ];
};

export default AdminAddTransactionHook;
