import {
  USER_ADD_ADDRESS,
  GET_USER_ADDRESS_BY_ID,
  DELETE_USER_ADDRESS,
  USER_EDIT_ADDRESS,
  GET_ONE_ADDRESS,
} from "../type";
const inital = {
  userAddAddress: [],
  getUserAddAddress: [],
  deleteUserAddAddress: [],
  editUserAddAddress: [],
  oneUserAddAddress: [],
};

const userAddressReducer = (state = inital, action) => {
  switch (action.type) {
    case USER_ADD_ADDRESS:
      return {
        ...state,
        userAddAddress: action.payload,
      };
    case GET_USER_ADDRESS_BY_ID:
      return {
        ...state,
        getUserAddAddress: action.payload,
      };
    case DELETE_USER_ADDRESS:
      return {
        ...state,
        deleteUserAddAddress: action.payload,
      };
    case USER_EDIT_ADDRESS:
      return {
        ...state,
        editUserAddAddress: action.payload,
      };
    case GET_ONE_ADDRESS:
      return {
        ...state,
        oneUserAddAddress: action.payload,
      };

    default:
      return state;
  }
};

export default userAddressReducer;
