import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminAddBrand from "../../Components/Admin/AdminAddBrand";
import AdminCartReport from "../../Components/Admin/AdminCartReport";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import SideBar from "../../Components/Admin/SideBar";

const AdminCartReportPage = () => {
    return (
        <div className="d-flex justify-content-start">
            <div>
                <SideBar />
            </div>
            <div className="p-4" style={{ width: '100%' }}>
                <AdminCartReport />
            </div>
        </div>
    );
};

export default AdminCartReportPage;
