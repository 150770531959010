import React from "react";
import { Card, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";

const SubCategoryCard = ({ background, img, title, id }) => {
  const navigate = useNavigate();

  return (

    <Col xs="4" sm="4" md="3" lg="2" className="d-flex forMobile" style={{
      justifyContent: 'center', width: '130px', height: '130px',
      marginBottom: '12px',
      padding: '0px 10px 0px 10px !important'

    }}>

      <Card
        className="my-1"
        style={{
          borderRadius: "8px",
          border: "none",
          backgroundColor: "#FFFFFF",
          boxShadow: "0 2px 2px 0 rgba(151,151,151,0.5)",
          justifyContent: 'center',
          width: '100%'

        }}
      >
        <Fade >

          <Card.Img
            onClick={() => navigate(`/products/subCategory/${id}`)}

            style={{
              height: "60px",
              width: "95%",
              objectFit: "contain",
              cursor: "pointer",
              alignSelf: "center",
            }}
            src={img}
          />
          <p className="categoty-card-text my-1" style={{
            padding: '5px'
          }}>{title}</p>
        </Fade>
      </Card>
    </Col >

    // <Col
    //   className="my-2 d-flex col-lg-2 col-md-4 col-sm-4 col-xs-4 categoty-card marginSubCategory"

    // >
    //   <div className="allCard mb-2 ">
    //     <Link to={`/products/subCategory/${id}`} style={{ textDecoration: 'none' }}>
    //       <div
    //         className="displayFlex"
    //       // style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}
    //       >
    //         <img alt="categotyCardImg" src={img} className="categoty-card-img" />
    //         <p className="categoty-card-text my-1">{title}</p>
    //       </div>
    //     </Link>
    //   </div>
    // </Col>
  );
};

export default SubCategoryCard;
