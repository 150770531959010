import axios from "axios";
//http://api.tejaan.sa:4000

//const baseURL = axios.create({ baseURL: "https://tejaan.sa/" });
// const baseURL = axios.create({ baseURL: "https://shelly-az.com" });

// const baseURL = axios.create({
//   baseURL: "https://yly.sa:4001",
// });
// const baseURL = axios.create({ baseURL: "http://demo.megastore-app.com:3000" });
// const baseURL = axios.create({ baseURL: "http://api.abayatlyan.com:3000" });
// const baseURL = axios.create({ baseURL: "http://localhost" });
// const baseURL = axios.create({
//   baseURL: "localhost:4000/",
// });

const baseURL = axios.create({ baseURL: "https://abu6.com:4005" });

// const baseURL = axios.create({ baseURL: "http://167.172.53.107" });

// const baseURL = axios.create({ baseURL: "http://143.110.174.151" });

export default baseURL;
