import React from "react";
import { Col, Row, Table ,Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import EditPaymentMethodHook from "../../hook/checkout/EditPaymentMethodHook";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import addSetting from "../../Images/add-setting.png";



const EditPaymentMethod = () => {
  const { t } = useTranslation()
  const { id } = useParams();

  const [
    paymentMethod_Ar,
    paymentMethod_En,
    paymentMethodStatus,
    onchangePaymentMethod_Ar,
    onchangePaymentMethod_En,
    onchangePaymentMethodStatus,
    show,
    handleShow,
    deleteElement,
    key,
    onChangeKey,
    value,
    onChangeValue,
    clickHandler,
    tablekeysValues,
    paymentToken,
    onChangePaymentToken,
    onSubmit
  ] = EditPaymentMethodHook(id)
  const settingsRes = useSelector((state) => state.settingsReducers.settings);



  return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">{t("Edit payment method")} </div>
        <Col sm="8">
          <input
            value={paymentMethod_Ar}
            onChange={onchangePaymentMethod_Ar}
            type="text"
            className="input-form d-block mt-3 px-3"
            placeholder={t("Payment method name")}
          />
          <input
            value={paymentMethod_En}
            onChange={onchangePaymentMethod_En}
            type="text"
            className="input-form d-block mt-3 px-3"
            placeholder={t("Payment method name")}
          />

          <input
          value={paymentToken}
          onChange={onChangePaymentToken}
          type="text"
          className="input-form d-block mt-3 px-3"
          placeholder="payment token"
        />

          <select
            value={paymentMethodStatus}
            onChange={onchangePaymentMethodStatus}
            name="type"
            id="type"
            className="select input-form-area mt-3 px-3 "
          >
            <option value="0">{t("the status")}</option>
            <option value="true">{t("active")}</option>
            <option value="false">{t("not active")}</option>
          </select>
        </Col>
      </Row>
      <Row>

      <label className="mt-2" htmlFor="setting" ><strong>{t("Add settings")} </strong></label>
      <div>
        <img
          id='setting'

          src={addSetting}
          alt=""
          width="50px"
          style={{ cursor: "pointer" }}
          onClick={() => handleShow()}
        />

      </div>
      {show ? <div>
        <input
          style={{
            borderColor: "gray",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "6px",
          }}
          value={key}
          onChange={onChangeKey}
          className="d-inline-block  mt-2 p-1 "
          placeholder="key"
        />
        <input
          style={{
            borderColor: "gray",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "6px",
          }}
          value={value}
          onChange={onChangeValue}
          className="d-inline-block mx-3 mt-2 p-1 "
          placeholder="value"
        />
        <button
          onClick={() => clickHandler()}
          className="btn-save d-inline mt-2 "
          style={{ backgroundColor: settingsRes?.data?.primaryColor }}
        >
          {t("add")}
        </button>
      </div> : ''}

        <Col sm="8" className="d-flex justify-content-end ">
          <button onClick={onSubmit} className="btn-save d-inline mt-2 " style={{ backgroundColor: settingsRes?.data?.primaryColor }}>{t("save")}</button>
        </Col>
      </Row>

      {show ? <Col sm-8>
        <Table striped bordered hover className="mt-3 mx-5 w-50" style={{ textAlign: 'center' }}>
          <thead>
            <tr>
              <th>#</th>
              <th> {t("Key")}</th>
              <th colSpan='2'>{t("Value")} </th>
            </tr>
          </thead>
          <tbody>
            {
              tablekeysValues ? tablekeysValues.map(el => (
                <tr>
                  <td>{el.count}</td>
                  <td>{el.key}</td>
                  <td >{el.value}</td>
                  <td><i onClick={() => deleteElement(el.count)} className="fa-regular fa-trash-can"></i></td>


                </tr>
              )) : ''
            }

          </tbody>
        </Table>
      </Col> : ''}
      <ToastContainer />
    </div>
  )

}

export default EditPaymentMethod