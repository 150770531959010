import React from "react";
import AdminCoupons from "../../Components/Admin/AdminCoupons";
import SideBar from "../../Components/Admin/SideBar";

const AdminCouponsPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
        <AdminCoupons />
      </div>
    </div>
  );
};

export default AdminCouponsPage;
