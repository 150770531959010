import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminEditProduct from "../../Components/Admin/AdminEditProduct";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import SideBar from "../../Components/Admin/SideBar";
import VendorSideBar from "../../Components/Vendor/VendorSideBar";

const AdminEditProductsPage = ({type}) => {
  return (
    // <Container className="py-3">
    //   <Row className="py-3">
    //     <Col sm="3" xs="2" md="2">
    //     <SideBar />
    //     </Col>
    //     <Col sm="9" xs="10" md="10">
    //       <AdminEditProduct />
    //     </Col>
    //   </Row>
    // </Container>
    <div className="d-flex justify-content-start">
      <div>

      {type === "admin"? <SideBar /> :<VendorSideBar/>}

       
      </div>
      <div className="p-4" style={{ width: '100%' }}>
        <AdminEditProduct />
      </div>
    </div>

  );
};

export default AdminEditProductsPage;
