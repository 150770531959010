import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminProductReviewsHook from "../../hook/admin/AdminProductReviewsHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import { Button, Modal } from "react-bootstrap";

const AdminProductReviews = () => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const navigate = useNavigate();

  const [
    loadingSpinner,
    allReviewsOfPrduct,
    deleteModal,
    showDeleteModal,
    closeDeleteModal,
    deleteReview,
  ] = AdminProductReviewsHook(productId);

  let columns = [
    {
      name: t("Added by"),
      selector: (row) => row.user?.firstname + `  ` + row.user?.lastname,
      cell: (row) => (
        <view
          style={{ cursor: "pointer" }}
          variant="secondary"
          onClick={() => navigate(`/admin/edituser/${row.user._id}`)}
          id={row._id}
        >
          {`${row.user?.firstname}    ${row.user?.lastname}`}
          <i className="fa fa-folder-open-o mx-1" />
        </view>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      sortable: true,
    },
    {
      name: t("rating"),
      selector: (row) => row["rate"],
      sortable: true,
    },
    {
      name: t("comment"),
      selector: (row) => row["comment"],
      sortable: true,
    },
    {
      name: t("delete"),
      cell: (row) => (
        <Button
          variant="danger"
          onClick={() => showDeleteModal(row._id)}
          id={row._id}
        >
          {t("delete")}
          <i className="fa fa-trash mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: allReviewsOfPrduct,
  };

  return (
    <div>
      {/********** Modal To Delete The Offer From The Product *****************/}
      <Modal show={deleteModal} onHide={closeDeleteModal}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Confirm deletion")} </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">
            {t("Are you sure about the deletion process?")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="font"
            variant="secondary"
            onClick={closeDeleteModal}
          >
            {t("retreat")}
          </Button>
          <Button className="font" variant="danger" onClick={deleteReview}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
      {/************************************************************************/}

      <div className="d-flex justify-content-between">
        <div className="admin-content-text">{t("Comments Management")} </div>
      </div>
      {loadingSpinner === true ? (
        <LoadingSpinner />
      ) : (
        <DataTableExtensions
          {...tableData}
          filterPlaceholder={t("search here")}
        >
          <DataTable
            noHeader
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      )}

      <ToastContainer />
    </div>
  );
};

export default AdminProductReviews;
