import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminEditAds from "../../Components/Admin/AdminEditAds";
import AdminEditProduct from "../../Components/Admin/AdminEditProduct";
import AdminEditSlider from "../../Components/Admin/AdminEditSlider";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import SideBar from "../../Components/Admin/SideBar";

const AdminEditAdsPage = ({ type }) => {
    return (
        <div className="d-flex justify-content-start">
            <div>
                <SideBar />
            </div>
            <div className="p-4" style={{ width: '100%' }}>
                {/* <AdminEditProduct /> */}
                <AdminEditAds />
            </div>
        </div>

    );
};

export default AdminEditAdsPage;
