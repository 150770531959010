import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBrand } from "../../redux/actions/brandAction";
import { getAllCategory } from "../../redux/actions/categoryAction";

const SideBarSearchHook = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const run = async () => {
      await dispatch(getAllCategory(6));
      await dispatch(getAllBrand(6));
    };
    run();
    window.scrollTo(0, 0);
  }, [dispatch]);
  const allCat = useSelector((state) => state.allCategory.category);
  const allBrand = useSelector((state) => state.allBrand.brand);

  let category = [];
  if (allCat.data) category = allCat.data;

  let brands = [];
  if (allBrand?.data) brands = allBrand?.data;

  return [category, brands];
};

export default SideBarSearchHook;
