import AdminTickets from "../../Components/Admin/AdminTickets"
import SideBar from "../../Components/Admin/SideBar";

const AdminTicketsPage = () => {

    return (

      <div className="d-flex justify-content-start">
      <div>
        <SideBar/>
      </div>
      <div className="p-4" style={{ width: '100%' }}>
      <AdminTickets/>
      </div>
    </div>

    )
}
export default AdminTicketsPage