import React from "react";
import { Button, Image, Modal } from "react-bootstrap";
import AddCatHook from "../../hook/category/AddCatHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import add from "../../Images/add.png";
import { ToastContainer } from "react-toastify";
import AddButton from "../Utilities/AddButton";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { BiCopy } from "react-icons/bi";

import Cookies from "js-cookie";
const AdminCategories = () => {
  const { t } = useTranslation();
  const [
    img,
    nameAr,
    nameEn,
    isPress,
    loading,
    handleSubmit,
    onImageChange,
    onChangeNameAr,
    onChangeNameEn,
    categories,
    show,
    handleShow,
    handleClose,
    handleDelete,
    clickHandler,
    handleShowFun,
    settingsRes,
  ] = AddCatHook();

  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  let newCategories = categories.map((category) => {
    return {
      ...category,
      updatedAt: formatDate(category.updatedAt),
      statusTitle: category.status == true ? t("active") : t("not active"),
    };
  });

  let columns = [
    {
      name: t("image"),
      cell: (row) => (
        <img
          src={row.image}
          alt="categoryImage"
          width="60px"
          height="60px"
          style={{ padding: "3px", marginLeft: "5px", objectFit: "contain" }}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("category name in arabic"),
      selector: (row) => row.nameAr,
      sortable: true,
    },

    {
      name: t("category name in english"),
      selector: (row) => row.nameEn,
      sortable: true,
    },
    // {
    //   name: "حالة التصنيف ",
    //   selector: "status",
    //   sortable: true,
    // },
    {
      name: t("category status"),
      selector: (row) => row.statusTitle,
      sortable: true,
    },

    {
      name: t("Date created"),
      selector: (row) => row.updatedAt,
      sortable: true,
    },

    {
      name:
        settingsRes.data?.gallaryTheme === true
          ? t("المعارض")
          : t("Subcategories"),
      cell: (row) => (
        <view
          style={{ cursor: "pointer" }}
          variant="secondary"
          onClick={() => navigate(`/admin/subcategories/${row._id}`)}
          id={row._id}
        >
          {t("press here")}
          <i className="fa fa-folder-open-o mx-1" />
        </view>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },

    // {
    //   name: t("  نسخ الصنف"),
    //   cell: (row) => (
    //     <Button onClick={() => alert(row._id)}>
    //       <BiCopy />
    //     </Button>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    // },

    {
      name: t("Modification"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    {
      name: t("the status"),
      cell: (row) =>
        row.status == true ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false);
            }}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true);
            }}
            id={row._id}
          >
            {t("activation")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: newCategories,
  };

  return (
    <div>
      <Helmet>
        <title>
          {`  ${t("categories")}   - ${settingsRes.data?.titleAr}`}{" "}
        </title>
      </Helmet>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Confirm deletion")} </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">
            {t("Are you sure about the deletion process?")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="font" variant="secondary" onClick={handleClose}>
            {t("retreat")}
          </Button>
          <Button className="font" variant="danger" onClick={handleDelete}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex justify-content-between">
        <div className="admin-content-text">{t("categories Management")}</div>
        <AddButton title={t("add category")} navigateTo="/admin/addcategory" />
      </div>

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable noHeader defaultSortAsc={false} highlightOnHover />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
};

export default AdminCategories;

// {
//   /**
//     {
//   name: "الحذف",
//   cell: (row) => (
//     <Button
//       variant="danger"
//       onClick={() => handleShow(row._id)}
//       id={row._id}
//     >
//       حذف
//       <i className="fa fa-trash mx-1" />
//     </Button>
//   ),
//   ignoreRowClick: true,
//   allowOverflow: true,
//   button: true,
// },
//    */
// }
