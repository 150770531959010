import React, { useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteAddressHook from "../../hook/user/DeleteAddressHook";
import { useTranslation } from "react-i18next";
import deleteicon from "../../Images/delete.png";
const UserAddressCard = ({ item }) => {
  const { t } = useTranslation()
  const [show, handleClose, handleShow, handleDelete] = DeleteAddressHook(
    item._id
  );
  return (
    <div className="user-address-card-2 my-3 px-2">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Confirm deletion")}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">{t("Are you sure about the deletion process?")}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="font" variant="secondary" onClick={handleClose}>
           {t("retreat")}
          </Button>
          <Button className="font" variant="danger" onClick={handleDelete}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Row className="d-flex justify-content-between  ">
        <Col xs="6">
          <div className="p-2">{item.name}</div>
        </Col>
        <Col xs="6" className="d-flex d-flex justify-content-end">
          <div className="d-flex p-2">
            <Link
              to={`/user/edit-address/${item._id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="d-flex mx-2">
                <img
                  alt=""
                  className="ms-1 mt-2"
                  src={deleteicon}
                  height="17px"
                  width="15px"
                />
                <p className="item-delete-edit"> {t("Modify")}</p>
              </div>
            </Link>
            <div className="d-flex " onClick={handleShow}>
              <img
                alt=""
                className="ms-1 mt-2"
                src={deleteicon}
                height="17px"
                width="15px"
              />
              <p className="item-delete-edit"> {t("move")}</p>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs="12">
          <div
            style={{
              color: "#555550",
              fontFamily: "Almarai",
              fontSize: "14px",
            }}
          >
            {item.address}
          </div>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col xs="12" className="d-flex">
          <div
            style={{
              color: "#555550",
              fontFamily: "Almarai",
              fontSize: "16px",
            }}
          >
            {t("phone")}:
          </div>

          <div
            style={{
              color: "#979797",
              fontFamily: "Almarai",
              fontSize: "16px",
            }}
            className="mx-2"
          >
            {item.mobile}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UserAddressCard;
