import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import add from "../../Images/add.png";


const AddButton = ({ title, navigateTo }) => {
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const navigate = useNavigate()
  return (
    <div className="m-1">
      <Button
        style={{ backgroundColor: settingsRes?.data?.primaryColor, borderRadius: '6px', borderColor: 'greenyellow' }}
        variant="secondary"
        onClick={() => navigate(`${navigateTo}`)}
      >
        {title}
        <img
          src={add}
          alt=""
          width="30px"
          height="35px"
          style={{ cursor: "pointer", marginRight: "8px", borderColor: 'black' }}
          onClick={() => navigate(`${navigateTo}`)}
        />
      </Button>
    </div>
  )
}

export default AddButton