import SideBar from "../../Components/Admin/SideBar";
import AllOffers from "../../Components/Offers/AllOffers";

const OffersPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
        <AllOffers />
      </div>
    </div>
  );
};

export default OffersPage;
