import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  FormControl,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";

import "./Header.css";
import { BsQuestionCircleFill, BsSearch } from "react-icons/bs";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import UserCartHook from "../../hook/cart/UserCartHook";
import user from "../../Images/user.png";
import shoppingCart from "../../Images/shopping-cart.png";

import slide1 from "../../Images/four.jpeg"; // Adjust the path as necessary
import slide2 from "../../Images/four.jpeg"; // Adjust the path as necessary
import logo from "../../Images/logo-1.png"; // Adjust the path as necessary
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getAllSlider } from "../../redux/actions/sliderActions";
import SliderGallery from "./SliderGallery";
import GetConfigDataHook from "../../hook/configer/GetConfigDataHook";

const Header = () => {
  const location = useLocation();
  const languages = [
    {
      code: "en",
      name: "English",
      dir: "ltr",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
    },
  ];
  const [t, i18n] = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const [webSiteData, settings] = GetConfigDataHook();

  const handleGoogleEvent = () => {
    ReactGA.event({
      category: "cart",
      action: "cart_details_action",
      label: "cart_label",
    });
  };
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    // var titleAr = settingsRes?.data?.titleAr
    // document.title = titleAr ? titleAr : ''
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage]);
  // let word = "";
  // if (localStorage.getItem("searchWord") != null) {
  //   word = localStorage.getItem("searchWord");
  // }
  const [userData, setUserData] = useState("");
  useEffect(() => {
    if (localStorage.getItem("user") != null) {
      setUserData(JSON.parse(localStorage.getItem("user")));
    }
  }, []);

  const logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("page");
    localStorage.removeItem("token");

    setUserData("");
  };
  const [res] = UserCartHook();
  const navigate = useNavigate();
  const navProduct = () => {
    navigate("/admin/products");
  };

  return (
    <header>
      <nav
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        {/* <img src={logo} /> */}
        <img alt="Logo" src={settings?.data?.logo} className="logo" />

        <ul className="hide-on-mobile">
          <li>
            <Link to="/">
              <a>الرئيسية</a>
            </Link>
          </li>
          <li>
            <a href="#exhibitions">المعارض</a>
          </li>
          <li>
            <a href="#artists">الفنانين</a>
          </li>
          <li>
            <a href="#about">من نحن</a>
          </li>
          <li>
            <a href="#contact">تواصل معنا</a>
          </li>
        </ul>
        <Nav className="" style={{ color: "#fff" }}>
          {i18n.language === "en" ? (
            <Nav.Link
              onClick={() => i18n.changeLanguage("ar")}
              className="nav-text langText d-flex mt-3 justify-content-center"
              style={{ color: "#fff" }}
            >
              عربي
            </Nav.Link>
          ) : (
            <Nav.Link
              onClick={() => i18n.changeLanguage("en")}
              className="nav-text langText d-flex mt-3 justify-content-center"
              style={{ color: "#fff" }}
            >
              English
            </Nav.Link>
          )}
          {userData != "" ? (
            <NavDropdown
              title={userData.firstname}
              id="basic-nav-dropdown"
              menuVariant="dark"
            >
              <div
                style={
                  {
                    // backgroundColor: `${settings?.data?.primaryColor}`,
                  }
                }
              >
                {userData.role === "admin" ? (
                  <NavDropdown.Item onClick={navProduct}>
                    <Link
                      to="/admin/products"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      {t("control panel")}
                    </Link>
                  </NavDropdown.Item>
                ) : userData.role === "user" ? (
                  <Fragment>
                    <NavDropdown.Item
                      style={{
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                      }}
                      onClick={() => navigate("/user/profile")}
                    >
                      <Link
                        to="/user/profile"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        {t("profile")}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => navigate("/user/allorders")}
                      style={{
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                      }}
                    >
                      <Link
                        to="/user/allorders"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        {t("orders")}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => navigate("/user/favoriteproducts")}
                      style={{
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                      }}
                    >
                      <Link
                        to="/user/favoriteproducts"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        {t("Prefered products")}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => navigate("/user/addresses")}
                      style={{
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                      }}
                    >
                      <Link
                        to="/user/addresses"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        {t("personal addresses")}
                      </Link>
                    </NavDropdown.Item>
                  </Fragment>
                ) : (
                  <NavDropdown.Item href="/vendor/profile">
                    {t("profile")}
                  </NavDropdown.Item>
                )}
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <Link
                    onClick={logOut}
                    to="/login"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    {t("logout")}
                  </Link>
                </NavDropdown.Item>
              </div>
            </NavDropdown>
          ) : (
            <NavLink
              to="/login"
              style={({ isActive }) => ({
                backgroundColor: isActive ? "transparent" : null,
                textDecoration: isActive ? "none" : "none",
                borderRight: isActive ? "none" : null,
                marginBottom: isActive ? "10px" : "10px",
              })}
              className="nav-text d-flex mt-3 justify-content-center"
            >
              <p style={{ color: "white", marginTop: "7px" }}>{t("login")}</p>
              <img
                style={{ marginTop: "6px" }}
                src={user}
                className="login-img"
                alt="sfvs"
              />
            </NavLink>
          )}

          {userData.role === "user" && (
            <NavLink
              to="/cart"
              className="nav-text d-flex mt-3 justify-content-center position-relative"
              style={({ isActive }) => ({
                backgroundColor: isActive ? "transparent" : null,
                textDecoration: isActive ? "none" : "none",
                borderRight: isActive ? "none" : null,
                marginBottom: isActive ? "10px" : "10px",
                color: isActive ? "white" : "white",
              })}
              onClick={handleGoogleEvent}
            >
              <p style={{ color: "white" }}>{t("cart")}</p>
              <img src={shoppingCart} className="login-img" alt="sfvs" />

              <span className="position-absolute top-5 start-0 translate-middle badge rounded-pill bg-danger">
                {res?.numberOfCart || 0}
              </span>
            </NavLink>
          )}

          {userData.role === "admin" &&
            location.pathname.startsWith("/admin/") && (
              <Nav.Link
                href="/admin/learning"
                className="nav-text d-flex mt-2 justify-content-center position-relative"
              >
                <span
                  tabindex="0"
                  data-toggle="tooltip"
                  title={t("Go to the educational page")}
                  as="p"
                  animation="wave"
                  variant="info"
                  className="text-light fs-5 d-inline-block"
                >
                  <BsQuestionCircleFill />
                </span>
              </Nav.Link>
            )}
          {userData.role === "vendor" &&
            location.pathname.startsWith("/vendor/") && (
              <Nav.Link
                href="/vendor/learning"
                className="nav-text d-flex mt-2 justify-content-center position-relative"
              >
                <span
                  tabindex="0"
                  data-toggle="tooltip"
                  title={t("Go to the educational page")}
                  as="p"
                  animation="wave"
                  variant="info"
                  className="text-light fs-5 d-inline-block"
                >
                  <BsQuestionCircleFill />
                </span>
              </Nav.Link>
            )}
        </Nav>
      </nav>
    </header>
  );
};

export default Header;
