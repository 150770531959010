import React from "react";
import AdminCategories from "../../Components/Admin/AdminCategories";

import SideBar from "../../Components/Admin/SideBar";

const AdminCategoriesPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
        <AdminCategories />
      </div>
    </div>
  );
};

export default AdminCategoriesPage;