import { useGetDataToken } from "../../hooks/useGetData";
import { useInsertData } from "../../hooks/useInsertData";
import { useUpdateData } from "../../hooks/useUpdateData";
import { ADD_TRANSACTION, GET_ONE_TRANSACTION, UPDATE_ONE_TRANSACTION, USER_TRANSACTION } from "../type";

export const addTransaction = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/wallet`, body);
    // console.log('response')
    // console.log(response)
    // console.log(body)
    dispatch({
      type: ADD_TRANSACTION,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADD_TRANSACTION,
      payload: error.response,
    });
  }
};

//get one transaction 
export const getOneTransaction = (id) => async(dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/wallet/${id}`)
    dispatch({
      type:GET_ONE_TRANSACTION,
      payload:response
    })
  } catch (error) {
    dispatch({
      type:GET_ONE_TRANSACTION,
      payload:error.response
    })
  }
}


// get all user transaction
export const userTransaction = (userId) => async(dispatch) => {
    try {
        const response = await useGetDataToken(`/api/v1/wallet/transactions/${userId}`)
        dispatch({
            type:USER_TRANSACTION,
            payload: response,
          });
    } catch (error) {
        dispatch({
            type: USER_TRANSACTION,
            payload: error.response,
          });
    }
}

// update one Transaction 
export const updateTransaction = (id,body) => async(dispatch) => {
  try {
    
    const response = await useUpdateData(`/api/v1/wallet/${id}`,body)
    dispatch({
      type:UPDATE_ONE_TRANSACTION,
      payload:response
    })
    
  } catch (error) {
    dispatch({
      type:UPDATE_ONE_TRANSACTION,
      payload:error.response
    })
  }
}